import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RegisterUserDto, UpdateUserDto, UserDto } from '@typesBase/user-dto';

@Injectable()
export class UsersApiService {
  private route = 'users';

  constructor(private http: HttpClient) {}

  getUserProfile(): Observable<UserDto> {
    return this.http.get<UserDto>(`${this.route}/profile`);
  }

  updateUserProfile(user: UpdateUserDto): Observable<UserDto> {
    return this.http.put<UserDto>(`${this.route}/profile`, user);
  }

  onBoardUser(): Observable<UserDto> {
    return this.http.put<UserDto>(`${this.route}/onboard`, {});
  }

  registerUser(user: RegisterUserDto): Observable<UserDto> {
    return this.http.post<UserDto>(`${this.route}/register`, user);
  }
}
